<template>
  <div class="notice" v-if="hasResource('message_management')">
    <div class="search-form">
      <el-select
        v-model="type"
        placeholder="新增推送"
        class="selectType"
        popper-class="selectOption"
        v-if="hasResource('message_add')"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.name"
          :label="item.name"
          :value="item.value"
        >
          <div class="typtItem">
            <i
              v-if="item.value == 'text'"
              class="iconfont"
              style="margin-right: 14px"
              >&#xe67d;</i
            >
            <i
              v-if="item.value == 'img'"
              class="iconfont"
              style="margin-right: 14px"
              >&#xe67c;</i
            >
            <span>{{ item.name }}</span>
          </div>
        </el-option>
      </el-select>
      <div class="dis-fix">
        <el-form :inline="true" ref="form" :model="form">
          <el-form-item label="编号" prop="id" class="fw-500">
            <el-input
              v-model="form.id"
              placeholder="请输入编号检索"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="类型" class="fw-500">
            <el-select v-model="form.type" >
              <el-option
                v-for="item in typeArr"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推送标题" prop="title" class="fw-500">
            <el-input
              v-model="form.title"
              placeholder="请输入标题检索"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="推送时间">
            <el-date-picker
              v-model="form.scheduled_at"
              style="width: 168px"
              format="yyyy.MM.dd"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="请选择"
              end-placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="状态" class="fw-500">
            <el-select v-model="form.status">
              <el-option
                v-for="item in statusArr"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-btn">
          <span class="serach-button" @click="search">查询</span>
          <span class="clear-button" @click="cancel">清空</span>
        </div>
      </div>
    </div>
    <el-table
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      :data="tableData"
      ref="multipleTable"
      tooltip-effect="dark"
      class="data-table"
      v-loading="isTableLoading"
    >
      <el-table-column
        v-for="col in tableColumns"
        :key="col.prop"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :fixed="col.fixed"
        :min-width="col.minWidth"
        :align="col.align"
      >
        <template slot-scope="{ row }">
          <div v-if="col.prop == 'id'">{{ row.id }}</div>
          <div v-else-if="col.prop == 'operation'" class="icon-btn">
            <el-button
              class="preview-color"
              v-if="hasResource('message_info') && row.status == 2"
              size="mini"
              type="text"
              @click="handlePreview(row)"
            >
              <i class="iconfont">&#xe662;</i>查看
            </el-button>
            <el-button
              class="preview-color"
              v-if="hasResource('message_update') && row.status != 2"
              size="mini"
              type="text"
              @click="handleEdit(row)"
            >
              <i class="iconfont">&#xe677;</i>编辑
            </el-button>
            <el-button
              class="preview-color"
              v-if="hasResource('message_send') && !row.status"
              size="mini"
              type="text"
              @click="handleSend(row)"
            >
              <i class="iconfont">&#xe661;</i>推送
            </el-button>
            <el-button
              class="preview-color"
              v-if="hasResource('message_delete') && !row.status"
              size="mini"
              type="text"
              @click="handleDelete(row)"
            >
              <i class="iconfont">&#xe65a;</i>删除
            </el-button>
            <el-button
              class="preview-color"
              v-if="
                hasResource('message_cancel') &&
                row.status == 1 &&
                !(row.scheduled_at == 0 || !row.scheduled_at)
              "
              size="mini"
              type="text"
              @click="handleCancel(row)"
            >
              <i class="iconfont">&#xe65d;</i>取消
            </el-button>
          </div>
          <div v-else-if="col.prop == 'type'">
            {{
              row.type == 1 ? "图文消息" : row.type == 2 ? "文本消息" : "暂无"
            }}
          </div>
          <div v-else-if="col.prop == 'status'">
            <div v-if="row.status == 1">
              {{
                row.scheduled_at == 0 || !row.scheduled_at ? "推送中" : "待推送"
              }}
            </div>
            <div v-else-if="row.status == 2">已推送</div>
            <div v-else>草稿</div>
          </div>
          <div v-else-if="col.prop == 'send_all_customers'">
            {{ sendAllCustomers[row.send_all_customers] }}
          </div>
          <div v-else-if="col.prop == 'scheduled_at'">
            {{
              row.scheduled_at == 0 || !row.scheduled_at
                ? "立即发送"
                : row.scheduled_at
            }}
          </div>
          <div v-else-if="col.prop == 'channel'">
          {{allChannel[row.channel || 0]}}
          </div>
          <div v-else>{{ row[col.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        v-if="total != 0"
      ></el-pagination>
    </div>
    <!-- 推送提示弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogSend"
      width="458px"
      class="pop-dialog sure-dialog"
    >
      <div class="sure-dialog-content">
        <span class="sure-tip">您将推送消息</span>
        <span class="suru-title">{{ handleItem.title }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="sendSubmit()">确认</el-button>
        <el-button class="cancel-button" @click="dialogSend = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除提示弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogDelete"
      width="458px"
      class="pop-dialog sure-dialog"
    >
      <div class="sure-dialog-content">
        <span class="sure-tip">您将删除</span>
        <span class="suru-title">{{ handleItem.title }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="deleteSubmit()"
          >确认</el-button
        >
        <el-button class="cancel-button" @click="dialogDelete = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 取消提示弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogCancel"
      width="458px"
      class="pop-dialog sure-dialog"
    >
      <div class="sure-dialog-content">
        <span class="sure-tip">您将取消</span>
        <span class="suru-title">{{ handleItem.title }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="cancelSubmit()"
          >确认</el-button
        >
        <el-button class="cancel-button" @click="dialogCancel = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看文本/图文 -->
    <viewDialog
        :dialog-view.sync="dialogView"
        :data="{...handleItem, time: handleItem.scheduled_at}"
        :langData="langData"
    />
  </div>
</template>

<script>
import api from "../../../api/message";
import viewDialog from '@/pages/_common/viewDialog/viewDialog.vue';
import { langData } from './edit/data';

export default {
  components: { viewDialog },
  watch: {
    type: function (newVal, oldVal) {
      if (newVal) {
        this.handleAdd(this.type);
      }
    },
    tableData(val) {
      this.doLayout();
    },
  },
  data() {
    return {
      langData,
      header: {
        url: "notice",
      },
      tableData: [],
      tableColumns: [
        { prop: "id", label: "通知编号", width: 180, align: "left" },
        { prop: "type", label: "类型", width: 180, align: "" },
        { prop: "title", label: "推送标题", width: 180, align: "", },
        { prop: "scheduled_at", label: "推送时间", width: 200, align: "" },
        {
          prop: "send_all_customers",
          label: "推送范围",
          width: 180,
          align: "",
        },
         { prop: "channel", label: "推送渠道", width: 200, align: "" },
        { prop: "status", label: "状态", width: 160, align: "" },
        {
          prop: "operation",
          label: "操作",
          minWidth: 200,
          align: "left",
          fixed: "right",
        },
      ],
      statusArr: [
        { name: "全部", value: "" },
        { name: "草稿", value: 0 },
        { name: "待推送", value: 1 },
        { name: "已推送", value: 2 },
      ],
      typeArr: [
        { name: "全部", value: "" },
        { name: "图文消息", value: 1 },
        { name: "文本消息", value: 2 },
      ],
      typeOptions: [
        { name: "文本消息", value: "text" },
        { name: "图文消息", value: "img" },
      ],
      sendAllCustomers: ["单独发送", "全部发送", "国内用户", "海外用户","关注用户"],
      allChannel: ['--', '仅通知', '仅邮件', '通知及邮件'],
      form: {
        id: "",
        type: "",
        status: "",
        title: "",
        scheduled_at: [],
      },
      rules: {
        id: [
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        title: [
          {
            min: 1,
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      currentPage: 1,
      pagesize: 10,
      total: 0,
      isTableLoading: false,
      dialogSend: false, //
      dialogCancel: false,
      dialogDelete: false,
      dialogView: false, // 预览弹窗
      handleItem: {},
      type: "",
      recordDatd: {},
      parameters: false,
    };
  },
  methods: {
    async init() {
      if (this.getLocalCurrentPage("needUpdate")) {
        // await this.updateFilterParams();
        await this.getList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getList();
      }
    },
    getList() {
      this.isTableLoading = true;
      let data = {
        filter: {
          type:  this.form.type,
          status: this.form.status,
          title: this.form.title,
          id:  this.form.id,
          scheduled_at: {
                from:
                  this.form.scheduled_at && this.form.scheduled_at.length > 1
                    ? this.form.scheduled_at[0]
                    : "",
                to:
                  this.form.scheduled_at && this.form.scheduled_at.length > 1
                    ? this.form.scheduled_at[1].split(" ")[0] + " 23:59:59"
                    : "",
              },
        },
        page: this.currentPage,
        limit:  this.pagesize,
      };

      api.messageList(data).then((res) => {
        if (res.data.code === 10200) {
          this.tableData = res.data.data.items;
          this.total = res.data.data.total;
          this.doLayout();
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    updateFilterParams() {
      this.recordDatd = this.getLocalCurrentPage("noticeList0415");
      let params = this.recordDatd;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.currentPage = params ? params.page : this.page;
        this.form.id = params ? params.id : this.form.id;
        this.form.status = params ? params.status : this.form.status;
        this.form.title = params ? params.title : this.form.title;
        this.form.scheduled_at = params
          ? params.scheduled_at
          : this.form.scheduled_at;
        this.form.type = params ? params.type : this.form.type;
        this.getList();
      }
    },
    doLayout() {
      let that = this;
      this.$nextTick(() => {
        that.$refs.multipleTable.doLayout();
      });
    },
    handleAdd(type) {
      if (type == "text") {
        this.$router.push("/message-info");
      } else if (type == "img") {
        this.$router.push("/message-info-img");
      }
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    cancel() {
      this.form = {
        id: "",
        type: "",
        status: "",
        title: "",
        scheduled_at: [],
      };
      this.$refs["form"].resetFields();
    },

    handleSizeChange(val) {
      this.parameters = false;
      this.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.currentPage = val;
      this.getList();
    },
    delete(id) {
      let ids = {
        id: id,
      };
      api.deleteCms(ids).then((res) => {
        if (res.data.code === 10200) {
          this.getList();
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    handleEdit(row) {
      let path = "/message-info";
      if (row.type == 1) {
        path = "/message-info-img";
      } else if (row.type == 2) {
        path = "/message-info";
      }
      this.$router.push({
        path: path,
        query: {
          id: row.id,
        },
      });
      const params = {
        limit: this.pagesize,
        page: this.currentPage,
        id: this.form.id,
        type: this.form.type,
        status: this.form.status,
        title: this.form.title,
        scheduled_at: this.form.scheduled_at,
      };
      this.recordCurrentPage("noticeList0415", params);
    },
    selectDate(row) {
      var date = new Date();
      var y = date.getFullYear() + "-",
          M = date.getMonth() + 1 + "-",
          d = date.getDate(),
          h = date.getHours() + ":",
          m = date.getMinutes() + ":",
          s = date.getSeconds();
      if (s < 10) {
        s = "0" + s;
      }
      var nowTime = y + M + d +' '+ h + m + s;
      var now = new Date(nowTime).getTime()
      var selectTime = row.scheduled_at;
      var select =new Date(selectTime).getTime();
      if (now > select) {
        this.$$error("推送时间不能小于当前时间，请修改推送时间");
        return true
      }
    },
    handleSend(row) {
      if(this.selectDate(row))
        return
      this.dialogSend = true;
      this.handleItem = row;
    },
    handleCancel(row) {
      this.dialogCancel = true;
      this.handleItem = row;
    },
    handleDelete(row) {
      this.dialogDelete = true;
      this.handleItem = row;
    },
    handlePreview(row) {
      // 针对历史消息，没有英文的情况，查看英文时展示中文标题和内容
      this.handleItem = {
        ...row,
        title_en: row.title_en|| row.title,
        content_en: row.content_en || row.content
      };
      this.dialogView = true;
    },
    sendSubmit() {
      let data = {
        id: this.handleItem.id,
      };
      api.messageSend(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogSend = false;
          this.handleItem = {};
          this.init();
          this.$$success("操作成功");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    deleteSubmit() {
      let data = {
        id: this.handleItem.id,
      };
      api.messageDelete(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogDelete = false;
          this.handleItem = {};
          this.init();
          this.$$success("操作成功");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    cancelSubmit() {
      let data = {
        id: this.handleItem.id,
      };
      api.messageCancel(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogCancel = false;
          this.handleItem = {};
          this.init();
          this.$$success("操作成功");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
  },
  created() {
    this.msgBus.$on("noticeList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
  activated() {
    if (this.$route.meta.keepAlive) {
      this.init();
    }
    this.type = "";
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
